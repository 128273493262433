import React, { Component } from "react";
import {
    message,
    Checkbox,
    Table,
    Statistic,
    Layout,
    Typography,
    Drawer,
    Divider,
    Space,
    Button,
    Form,
    Spin,
    Row,
    Col,
    Input,
    Select,
    DatePicker,
    Modal,
    Tooltip,
    InputNumber,
    List,
    Radio
} from "antd";

import "../../../../Styles/Modules/Finanzas/DrawerTimbrado.scss";
import { PrinterOutlined, CloseOutlined, } from "@ant-design/icons";


import { GoFileCode } from "react-icons/go";
import { IconSAT } from "../../../Widgets/Iconos";

import Decimal from "decimal.js";

import { c_TipoDeComprobante, c_RegimenFiscal, c_UsoCFDI, c_Exportacion, c_FormaPago, c_TipoDeRelación } from "../../../SATCatalogos/index";
import { FaRegHandScissors } from "react-icons/fa";

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography
const { Option } = Select


function convertNumbersToStrings(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'number') {
                obj[key] = new Decimal(obj[key]).toFixed(2).toString();
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                convertNumbersToStrings(obj[key]);
            }
        }
    }
    return obj;
}



const axios = require('axios')
const moment = require('moment')


/**
 * @export
 * @class EgresosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class TimbradoOrden extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disabled: false,
            newData: [],
            orden: {
                impuestos: 0,
                subtotal: 0,
            },
            total_original: 0,
            factura: [],
            facturas_nota_credito: [],
            moneda: "MXN",
            razon_social_id: null,


            razones_sociales: {
                data: [],
                page: 1,
                limit: 20,

                pages: 0,
                total: 0,

                search: null,
            },
            transacciones: {
                data: [],
                page: 1,
                limit: 20,

                pages: 0,
                total: 0,

                search: null,
            },
            facturas: {
                data: [],
                page: 1,
                limit: 100,

                pages: 0,
                total: 0,

                search: null,
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden()
        this.getTipoCambio()
        this.getRazonesSociales()

        if (this.props.transaccion_id) {
            this.getTransaccion()
            this.getTransacciones()
            this.getFacturas()
        }

        if (this.props.nota_credito_drawer) {
            this.getFacturas()
        }

    }

    /**
     * @memberof DrawerTimbrado
     * @method getOrden
     * @description Obtenemos las ordenes_detalles de una orden para el timbrado, para despues parsear ls datos y cargarlos 
     */
    getOrden = ({ orden_id = this.props.orden_id } = this.props) => {
        this.props.setLoading(true)
        axios.get('/syncfy/ordenes_detalles', {
            params: {
                orden_id,
                cliente_id: true
            }
        }).then(({ data }) => {

            this.parseOrden(data)
        }).catch(({ response }) => {
            message.error("Error al obtener los detalles de la orden")
        }).finally(() => this.props.setLoading(false))
    }

    /**
     * @memberof DrawerTimbrado
     * @method getOrden
     * @description Obtenemos las ordenes_detalles de una orden para el timbrado, para despues parsear los datos y cargarlos 
     */
    parseOrden = (orden = this.state.orden) => {
        let facturacion = {
            Serie: (orden.tipo_orden === 1) ? "SPT" : "VMI",
            Folio: (orden.folio_number).toString().padStart(7, "0"),
            TipoDeComprobante: "I",
            Receptor: {
                Nombre: orden?.cliente_id.razon_social,
                Rfc: orden?.cliente_id.rfc,
                DomicilioFiscalReceptor: orden?.cliente_id.codigo_postal,
                RegimenFiscalReceptor: orden?.cliente_id.regimen_fiscal,
            },
        }

        //Si no se pasa una transaccion ni es para notas de credito, se rellena la informacion de la factura con los detalles de la orden
        if (!this.props.transaccion_id && !this.props.nota_credito_drawer) {
            this.setState({ total_original: orden.total })
            facturacion.Total = orden.total;
            facturacion.SubTotal = orden.subtotal;
            facturacion.Impuestos = {
                TotalImpuestosTrasladados: orden.impuestos,
                TotalImpuestosRetenidos: 0
            }
            facturacion.Conceptos = orden.ordenes_detalles.map(e => {

                return ({
                    Unidad: e.producto_id?.unidad_nombre ?? "Pieza",
                    ClaveUnidad: e.producto_id?.unidad ?? "H87",
                    ValorUnitario: e?.info_matriz?.precio_venta_unitario ?? 0,
                    Cantidad: e.cantidad,
                    _id: e._id,
                    ClaveProdServ: e.producto_id.clave_producto ? e.producto_id.clave_producto : "50181906",
                    NoIdentificacion: e.producto_id.sku,
                    Importe: e?.info_matriz?.total_venta,
                    Descripcion: e.producto_id.descripcion,
                    Descuento: "0",
                    ObjetoImp: e.producto_id.impuestos ? "01" : "02",

                    //El campo deberia ser "libre de impuesto" pero se llama "impuesto"
                    //Se queda asi por la flojera de no hacer un seeder.
                    NoImpuesto: !e.producto_id.impuestos,
                    ...(!e.producto_id.impuestos ? {} : {
                        Impuestos: {
                            Traslados: [{
                                Base: e?.info_matriz?.total_venta,
                                Impuesto: '002',
                                TipoFactor: 'Tasa',
                                TasaOCuota: "0.160000",
                                Importe: Decimal(e.info_matriz.total_venta).mul(Decimal(0.16)).toDecimalPlaces(2).toNumber().toString(),
                            }],
                        }
                    }),
                })
            })


        }

        if (this.props.nota_credito_drawer) {
            facturacion.Serie = "NC"
            facturacion.TipoDeComprobante = "E"
            facturacion.Receptor = {
                Nombre: orden?.cliente_id.razon_social,
                Rfc: orden?.cliente_id.rfc,
                DomicilioFiscalReceptor: orden?.cliente_id.codigo_postal,
                RegimenFiscalReceptor: orden?.cliente_id.regimen_fiscal,
                UsoCFDI: "G02"
            }
            facturacion.Total = 0
            facturacion.SubTotal = 0
            facturacion.Impuestos = {
                TotalImpuestosTrasladados: 0,
                TotalImpuestosRetenidos: 0
            }
        }

        this.formTimbrado.current.setFieldsValue(facturacion)
        this.setState({ orden })
        setTimeout(() => {
            this.updateValores({ updateCuantities: true }, this.formTimbrado.current.getFieldsValue())
        }, 100);

    }

    /**
     * @memberof DrawerTimbrado
     * @method getTipoCambio
     * @description Obtenemos el tipo de cambio y cargarlo en la factura
     */
    getTipoCambio = () => {
        axios.get("/tipo-cambio/get")
            .then(({ data }) => {
                this.formTimbrado.current.setFieldsValue({ TipoCambio: data?.data[0]?.valor ?? 18 })
            })
    }

    /**
     *
     * @memberof DrawerTimbrado
     * @method getRazonesSociales
     * @description Obtiene el listado de los Razones Sociales
     */
    getRazonesSociales = ({
        page = this.state.razones_sociales.page,
        limit = this.state.razones_sociales.limit,
        search = this.state.razones_sociales.search
    } = this.state.razones_sociales) => {
        this.setState({ razones_sociales: { ...this.state.razones_sociales, page, limit, search } })
        axios.get('/razones-sociales', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                this.setState({
                    razones_sociales: {
                        ...this.state.razones_sociales,
                        data: data.data.itemsList,
                        total: data.data.paginator?.itemCount,
                        pages: data.data.paginator?.pageCount,

                    }
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error("Error al obtener las razones sociales")
            });
    }


    /**
     * @memberof DrawerTimbrado
     * @method getTransaccion
     * @description Obtenemos la transaccion, carga los datos en la factura 
     */
    getTransaccion = (transaccion_id = this.props.transaccion_id) => {
        this.props.setLoading(true)
        axios.post('/transacciones/get', {
            id: transaccion_id
        })
            .then(({ data }) => {
                let { monto, monto_dolar, _id } = data.data
                let Conceptos = [
                    {
                        "ClaveProdServ": "84111506",
                        "Cantidad": "1",
                        "ClaveUnidad": "ACT",
                        "Descripcion": "Pago",
                        "ValorUnitario": monto,
                        "Importe": monto,
                        "ObjetoImp": "01"
                    }
                ]
                this.formTimbrado.current.setFieldsValue({
                    Conceptos,
                    SubTotal: monto,
                    Total: monto,
                    TotalDolar: monto_dolar,
                    Impuestos: {
                        TotalImpuestosRetenidos: 0,
                        TotalImpuestosTrasladados: 0
                    },
                    transaccion_id: {
                        label: this.renderTransaccion(data.data),
                        value: _id,
                        key: _id,
                    },
                    TipoDeComprobante: "P",
                    Serie: "PE",
                    Folio: data.folio

                })
                this.setState({ total_original: monto })
            })
            .catch(({ response }) => {
                console.log("response X", response)
                message.error("Error al obtener la transacción")
            })
            .finally(() => this.props.setLoading(false))
    }


    getTransacciones = ({

        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.state.transacciones.search,

    } = this.state.transacciones) => {
        this.props.setLoading(true)
        this.setState({ transacciones: { ...this.state.transacciones, page, limit, search } })
        axios.post('/transacciones/list', {
            id: this.props.orden_id,
            ...this.props.cliente_id ? { cliente_id: this.props.cliente_id } : {}
        })
            .then(({ data }) => {
                this.setState({
                    transacciones: {
                        ...this.state.transacciones,
                        data: data.data?.itemsList,
                        pages: data.data?.pageCount,
                        total: data.data?.itemCount,
                    }
                })
            })
            .catch(({ response }) => {

            })
            .finally(() => this.props.setLoading(false))
    }

    /**
    * @memberof DrawerTimbrado
    * @method getFacturas
    * @description Obtenemos la lista de facturas relacionas a la orden y notas de credito en caso necesario 
    */
    getFacturas = ({
        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
        search = this.state.facturas.search,
    } = this.state.facturas) => {
        this.props.setLoading(true)
        this.setState({ facturas: { ...this.state.facturas, page, limit, search } })
        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                search,
                orden_id: this.props.orden_id,
                ...this.props.cliente_id ? { cliente_id: this.props.cliente_id } : {},
                ...this.props.vmi ? { vmi: this.props.vmi } : {},
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({
                    facturas: {
                        ...this.state.facturas,
                        data: data.data?.itemsList,
                        pages: data.data?.pageCount,
                        total: data.data?.itemCount,
                    }
                })
            })
            .catch(({ response }) => {

            })
            .finally(() => this.props.setLoading(false))
    }


    updateValores = (changedValues, allValues) => {

        let { Conceptos = [] } = allValues

        if (
            !Boolean(this.props.transaccion_id) &&
            (
                Boolean(changedValues.Conceptos) ||
                Boolean(changedValues.Descuento) ||
                Boolean(changedValues.descuento) ||
                (
                    Array.isArray(changedValues['CfdiRelacionados ']?.UUID) &&
                    changedValues['CfdiRelacionados ']?.UUID.length > 0
                ) ||
                Boolean(changedValues.DescuentoPorcentual) ||
                Boolean(changedValues.SubtotalDescuento) ||
                Boolean(changedValues.updateCuantities)
            )
        ) {
            let x = 0

            let update = {}


            let porcentaje = Decimal(allValues.DescuentoPorcentual || 0).div(100);

            let calculoImporte = (index) => {
                Conceptos[index].Cantidad = (Conceptos[index].Cantidad) ? Conceptos[index].Cantidad : 0
                Conceptos[index].ValorUnitario = (Conceptos[index].ValorUnitario) ? Conceptos[index].ValorUnitario : 0

                let importe = Decimal(Conceptos[index].Cantidad).mul(Decimal(Conceptos[index].ValorUnitario)).toDecimalPlaces(2).toNumber()
                // console.log('Conceptos[index]', Conceptos[index])
                Conceptos[index].Importe = importe
                if (!Conceptos[index].Impuestos)
                    Conceptos[index].Impuestos = {}

                if (!Array.isArray(Conceptos[index].Impuestos.Traslados) || Conceptos[index].Impuestos.Traslados.length == 0)
                    Conceptos[index].Impuestos.Traslados = [{ Base: 0, },]

                Conceptos[index].Impuestos.Traslados[0].Base = Conceptos[index].Importe

                return importe
            }


            let calculoDescuento = (index) => {
                let descuento = 0
                if (allValues.descuento === 'porcentual') {
                    descuento = Decimal(Conceptos[index].Importe).mul(porcentaje).toDecimalPlaces(2).toNumber()

                    Conceptos[index].Descuento = descuento
                    Conceptos[index].Impuestos.Traslados[0].Base = Decimal(Conceptos[index].Impuestos.Traslados[0].Base)
                        .sub(Conceptos[index].Descuento)
                        .toDecimalPlaces(2).toNumber()

                }
            }

            let calculoImpuesto = (index) => {

                let impuesto = Decimal(Conceptos[index].Impuestos.Traslados[0].Base).mul(Decimal(0.16)).toDecimalPlaces(2).toNumber()

                Conceptos[index].Impuestos.Traslados[0].Impuesto = Conceptos[index].NoImpuesto ? "001" : "002"
                Conceptos[index].Impuestos.Traslados[0].Importe = Conceptos[index].NoImpuesto ? 0 : impuesto
                Conceptos[index].ObjetoImp = Conceptos[index].NoImpuesto ? "01" : "02"
                return impuesto
            }

            let subtotal_sin_descuento = new Decimal(0);
            let subtotal = new Decimal(0);
            let descuento = new Decimal(0);
            let impuestos = new Decimal(0);
            let impuestosBase = new Decimal(0);


            for (let index = 0; index < Conceptos.length; index++) {

                calculoImporte(index)

                if (allValues.descuento != null)
                    calculoDescuento(index)

                calculoImpuesto(index)

                Conceptos[index].Total = Decimal(Conceptos[index].Impuestos.Traslados[0].Base)
                    .add(Conceptos[index].Impuestos.Traslados[0].Importe)
                    .toDecimalPlaces(2).toNumber()


                subtotal_sin_descuento = subtotal_sin_descuento.add(Conceptos[index].Importe || 0);
                subtotal =
                    (allValues.descuento != null) ?
                        subtotal.add(Conceptos[index].Impuestos.Traslados[0]?.Base || 0)
                        :
                        subtotal.add(Conceptos[index].Importe || 0);

                descuento = descuento.add(Conceptos[index].Descuento || 0);
                impuestos = impuestos.add(Conceptos[index].Impuestos.Traslados[0].Importe || 0);
                impuestosBase = impuestosBase.add(Conceptos[index].Impuestos.Traslados[0].Base || 0);
            }
            let total = subtotal.add(impuestos);

            subtotal = subtotal.toDecimalPlaces(2).toNumber()
            descuento = descuento.toDecimalPlaces(2).toNumber()
            impuestos = impuestos.toDecimalPlaces(2).toNumber()
            impuestosBase = impuestosBase.toDecimalPlaces(2).toNumber()
            total = total.toDecimalPlaces(2).toNumber()

            update = {
                Total: total,
                SubTotal: allValues.descuento ? subtotal_sin_descuento : subtotal,
                Descuento: descuento, // Nuevo campo para el descuento
                SubTotalDescuento: subtotal_sin_descuento,
                ImpuestosBase: impuestosBase,
                Impuestos: {
                    TotalImpuestosTrasladados: impuestos,
                },
                Conceptos: Conceptos
            };
            return this.formTimbrado.current.setFieldsValue(update)
        }
        if (
            Boolean(this.props.transaccion_id) && (
                changedValues.Total || changedValues.SubTotal || (Array.isArray(changedValues.Conceptos) && (changedValues.Conceptos[0]?.Importe || changedValues.Conceptos[0]?.ValorUnitario)) ||
                changedValues.Moneda ||
                changedValues.TipoCambio ||
                changedValues.TotalDolar)
        ) {

            // Si es la cantidad en PESOS
            let monto = changedValues.Total || changedValues.SubTotal || (Array.isArray(changedValues.Conceptos) && (changedValues.Conceptos[0]?.Importe || changedValues.Conceptos[0]?.ValorUnitario)) || allValues.Total

            //Si es la cantidad en dolares
            let TipoCambio = allValues.TipoCambio

            let monto_dolar = changedValues.TotalDolar

            if (changedValues.TotalDolar)
                monto = Decimal(monto_dolar).mul(TipoCambio).toDecimalPlaces(2).toNumber()
            else
                monto_dolar = Decimal(monto).div(TipoCambio).toDecimalPlaces(2).toNumber()

            let update = {}
            Conceptos[0].Importe = monto
            Conceptos[0].ValorUnitario = monto


            update = {
                Total: monto,
                SubTotal: monto,
                TotalDolar: monto_dolar,
                Conceptos
            }
            this.formTimbrado.current.setFieldsValue(update)
        }
    }

    /**
     * @memberof DrawerTimbrado
     * @method formatValues
     * @description Formatea los datos de la factura para enviarlos al back para realizar el timbrado
     */
    formatValues = (values) => {

        let { enviar_receptor, enviar_responsable, observaciones, transaccion_id, Total } = values, temp = values
        let { total_original } = this.state;

        if (Total === 0) {
            message.error("El valor total no puede ser igual a 0")
            return;
        }
        // Calcular el límite inferior y superior permitido
        var limiteInferior = Decimal(total_original).mul(0.5).toDecimalPlaces(2).toNumber();
        var limiteSuperior = Decimal(total_original).mul(1.5).toDecimalPlaces(2).toNumber();

        // Verificas si el nuevo total está dentro del rango permitido
        if (Total < limiteInferior || Total > limiteSuperior) {
            message.error("El valor total no es valido, sobrepasa los limites")
            return;
        }

        let { factura } = this.state

        transaccion_id = transaccion_id?.value || transaccion_id
        let FormaDePagoP = values.FormaPago

        delete values["enviar_receptor"]
        delete values["enviar_responsable"]
        delete values["transaccion_id"]
        delete values["observaciones"]



        let BaseImpuestos = values.SubTotal

        values.Conceptos.map(e => {
            if (e.ObjetoImp === "01") {
                delete e.Impuestos
                BaseImpuestos = Decimal(BaseImpuestos).sub(e.Importe).toDecimalPlaces(2).toNumber()
            }
        })



        let TipoCambio = (values.Moneda == "MXN") ? 1 : values.TipoCambio
        values = {
            ...values,
            Version: "4.0",
        }

        if (this.props.transaccion_id) {

            console.log(this.props.transaccion_id)

            delete values["TipoCambio"]
            values = {
                ...values,
                'Conceptos': [
                    {
                        "ClaveProdServ": "84111506",
                        "Cantidad": "1",
                        "ClaveUnidad": "ACT",
                        "Descripcion": "Pago",
                        "ValorUnitario": '0',
                        "Importe": '0',
                        "ObjetoImp": "01"
                    }
                ],
                'Moneda': "XXX",
                'Complemento': {
                    "Any": [
                        {
                            "Pago20:Pagos": {
                                "Pago": [
                                    {
                                        "FechaPago": moment(values.Fecha).format("YYYY-MM-DDThh:mm:ss"),
                                        "FormaDePagoP": FormaDePagoP,
                                        "MonedaP": values.Moneda,
                                        "Monto": (values.Moneda == "MXN") ? values.Total : values.TotalDolar,
                                        "NumOperacion": "12",
                                        "TipoCambioP": (values.Moneda == "MXN") ? String(TipoCambio) : TipoCambio,
                                        "DoctoRelacionado": factura.map(f => (
                                            {
                                                "IdDocumento": f.uuid,
                                                "Serie": f.serie,
                                                "Folio": f.folio,
                                                "MonedaDR": values.Moneda,
                                                "MetodoDePagoDR": f.metodo_pago,
                                                "NumParcialidad": "1",
                                                "ImpSaldoAnt": (values.Moneda == "MXN") ? f.total : Decimal(f.total).div(TipoCambio).toDecimalPlaces(2).toNumber(),
                                                "ImpPagado": (values.Moneda == "MXN") ? f.total : Decimal(f.total).div(TipoCambio).toDecimalPlaces(2).toNumber(),
                                                "ImpSaldoInsoluto": "0.00",
                                                "ObjetoImpDR": values["incluir_ImpuestosDR"] ? "02" : "01",
                                                "EquivalenciaDR": "1",
                                                ...(values["incluir_ImpuestosDR"] ? {
                                                    "ImpuestosDR": {
                                                        "TrasladosDR":[
                                                            {
                                                                "BaseDR": Decimal(f.total).div(1.16).toDecimalPlaces(2),  // Base gravable proporcional
                                                                "ImpuestoDR": "002",         // Clave de impuesto (002 = IVA)
                                                                "TipoFactorDR": "Tasa",      // Tipo de factor (Tasa/Fijo/Exento)
                                                                "TasaOCuotaDR": "0.160000",  // Tasa o cuota del impuesto (16% en este caso)
                                                                "ImporteDR": Decimal(f.total).div(1.16).mul(0.16).toDecimalPlaces(2)        // Importe proporcional del impuesto trasladado
                                                            }
                                                        ]
                                                        
                                                    }
                                                } : {})
                                            }
                                        )),
                                        ...(values["incluir_ImpuestosDR"] ? {
                                            "ImpuestosP": {
                                                "TrasladosP": [
                                                    {
                                                        "BaseP": Decimal(values.Total).div(1.16).toDecimalPlaces(2),
                                                        "ImpuestoP": "002",
                                                        "TipoFactorP": "Tasa",
                                                        "TasaOCuotaP": "0.160000",
                                                        "ImporteP": Decimal(values.Total).div(1.16).mul(0.16).toDecimalPlaces(2)
                                                    }
                                                ]
                                            }
                                        } : {})
                                    }
                                ],
                                "Totales": {
                                    "MontoTotalPagos": values.Total,
                                    ...(values["incluir_ImpuestosDR"] ? { 
                                        "TotalTrasladosBaseIVA16": Decimal(values.Total).div(1.16).toDecimalPlaces(2),  
                                        "TotalTrasladosImpuestoIVA16": Decimal(values.Total).div(1.16).mul(0.16).toDecimalPlaces(2), 
                                    } : {})
                                }
                            }
                        }
                    ]
                },
                'SubTotal': '0',
                'Total': '0',
                'Fecha': values.Fecha.format("YYYY-MM-DDThh:mm:ss")
            }
            delete values["TipoCambio"]
            delete values["TotalDolar"]
            delete values["FormaPago"]
            delete values["incluir_ImpuestosDR"]

        } else if (this.props.nota_credito_drawer) {

            console.log("this.props.nota_credito_drawer", this.props.nota_credito_drawer);
            let traslados = []

            for (let concepto of values.Conceptos) {
                traslados.push(...concepto.Impuestos.Traslados)
            }



            delete values.descuento
            delete values.DescuentoPorcentual
            delete values.SubtotalDescuento




            values = {
                ...values,
                Descuento: values.Descuento ?? 0,
                // DescuentoPorcentual: values.DescuentoPorcentual,
                TipoCambio: (values.Moneda == "MXN") ? String(TipoCambio) : TipoCambio,//String(TipoCambio),
                Impuestos: {
                    TotalImpuestosTrasladados: values.Impuestos.TotalImpuestosTrasladados,
                    Traslados: {
                        "0": {
                            "Base": values.ImpuestosBase,
                            "Importe": values.Impuestos.TotalImpuestosTrasladados,
                            "Impuesto": "002",
                            "TasaOCuota": "0.160000",
                            "TipoFactor": "Tasa"
                        }
                    }
                }
            }




            //return

        } else {

            console.log("NORMAL")

            if (values["FormaPago"] == "" || values["FormaPago"] == null) {
                delete values["FormaPago"]
            }

            values.TipoCambio = String(TipoCambio)
            values.Descuento = "0"
            values.Impuestos.Traslados = [
                {
                    Base: BaseImpuestos,
                    Impuesto: '002',
                    TipoFactor: 'Tasa',
                    TasaOCuota: '0.160000',
                    Importe: values.Impuestos.TotalImpuestosTrasladados
                }
            ]

            //recorremos los conceptos para validadr que en caso de tener impuestos vayan todos los campos completos
            for(let i = 0; i < values.Conceptos.length; i++){
                if(values?.Conceptos[i]?.Impuestos?.Traslados){
                    if(values?.Conceptos[i]?.Impuestos?.Traslados[0])
                        if(!values?.Conceptos[i]?.Impuestos?.Traslados[0].TasaOCuota) values.Conceptos[i].Impuestos.Traslados[0].TasaOCuota = "0.160000"
                        if(!values?.Conceptos[i]?.Impuestos?.Traslados[0].TipoFactor) values.Conceptos[i].Impuestos.Traslados[0].TipoFactor = "Tasa"
                }
            }

        }


            console.log("invoice_data: values", {invoice_data: values});
        /*         values = convertNumbersToStrings(values); */
        this.saveFactura({
            invoice_data: values,
            enviar_responsable,
            enviar_receptor,
            observaciones,
            orden_id: this.props.orden_id,
            transaccion_id,
            facturas_id: factura.map(e => e._id),

            moneda: temp.Moneda,
            tipo_cambio: TipoCambio,//: temp.TipoCambio,
            forma_pago: this.state.factura?.forma_pago,//: temp.FormaPago,
            metodo_pago: this.state.metodo_pago?.metodo_pago,//: temp.MetodoPago,

            total: Total,

        })
    }



    /**
     * @memberof DrawerTimbrado
     * @method saveFactura
     * @description Timbra la factura con Syncfy y la guarda
     */
    saveFactura = (values) => {
        this.props.setLoading(true)
        return axios.post('/syncfy/timbrado', {
            ...values,
            nota_credito_create: this.props.nota_credito_drawer ?? undefined,
        }).then(response => {
            message.success('¡Factura Timbrada exitosamente!')
            //this.getOrden()
            this.props.onSuccess()
        })
            .catch(error => {
                console.log(error)
                if (Array.isArray(error.response?.data?.data)) {
                    this.setState({ errores: error.response.data.data })
                    Modal.error({
                        title: 'Error al timbrar la factura',
                        content: <ul>
                            {error.response.data.data.map(e => <li>{e}</li>)}
                        </ul>
                    })
                } else {
                    message.error('Error al timbrar la facturas')
                }
            })
            .finally(() => this.props.setLoading(false))

    }

    /**
     * @memberof DrawerTimbrado
     * @method onSelectFacturasNotaCreadito
     * @description En caso de que se vaya a crear una nota de credito y se seleccione alguna factura, calcula la suma de los totales de la factura
     * y carga la info en el formulario     
     * */
    onSelectFacturasNotaCreadito = ({ value, content }) => {
        let facturas = [...this.state.facturas_nota_credito]
        facturas.push({ ...content })



        let monto = 0, imp_trasladados = 0

        for (let factura of facturas) {
            monto = Decimal(monto).add(factura.total).toDecimalPlaces(2).toNumber()
        }
        let conceptos_facturas = [
            {
                "ClaveProdServ": "84111506",
                "Cantidad": "1",
                "ClaveUnidad": "ACT",
                "Descripcion": "Pago",
                "ValorUnitario": monto,
                "Importe": monto,
                "ObjetoImp": "01"
            }
        ]
        if (content.conceptos?.length > 0) {
            conceptos_facturas = []
            monto = 0
            for (let factura of facturas) {

                if (factura.conceptos.length > 0) {
                    for (let cons of factura.conceptos) {
                        conceptos_facturas.push({ ...cons, NoImpuesto: false })
                        monto = Decimal(monto).add(parseFloat(cons.Importe)).toDecimalPlaces(2).toNumber()
                        imp_trasladados = Decimal(imp_trasladados).add(parseFloat(cons.Impuestos?.Traslados[0]?.Importe ?? 0)).toDecimalPlaces(2).toNumber()
                    }
                }
            }

        }
        this.setState({
            facturas_nota_credito: facturas
        }, () => {
            this.setState({
                total_original: monto,
            }, () => {

                let update = {
                    SubTotal: monto,
                    Total: Decimal(monto).add(imp_trasladados).toDecimalPlaces(2).toNumber(),
                    TotalDolar: Decimal(monto).div(this.state.TipoCambio ?? 18).toDecimalPlaces(2).toNumber(),
                    Impuestos: {
                        TotalImpuestosTrasladados: imp_trasladados,
                        TotalImpuestosRetenidos: 0,
                    },
                    Serie: "NC",
                    Conceptos: conceptos_facturas,
                }
                this.formTimbrado.current.setFieldsValue(update)
                setTimeout(() => {
                    this.updateValores(update, this.formTimbrado.current.getFieldsValue())
                }, 100);
            })
        })

    }

    formTimbrado = React.createRef()


    renderTransaccion = (transaccion) => <Space className="width-100" style={{ justifyContent: "space-between" }}>
        <Text strong>{transaccion.concepto} <small> <i>({moment(transaccion.createdAt).format("YY/MM/DD")})</i></small> <small><i>{(transaccion.cuenta_id?.nombre)}</i></small> </Text>
        <Text>{(transaccion.monto ?? 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>
    </Space>

    renderFactura = ({ concepto, serie, folio, uuid, monto, createdAt }) => <Space direction="vertical" className="width-100">
        <Space className="width-100" style={{ justifyContent: "space-between" }}>
            <Text strong>{concepto} <small>{serie}-{folio}</small> <i><small> ({moment(createdAt).format("YY/MM/DD")})</small> </i></Text>
            <Text>{(monto ?? 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>
        </Space>
        <Text italic>{uuid}</Text>
    </Space>

    /**
     * @return {*} 
     * @memberof EgresosList
     */
    render() {
        const { disabled } = this.state
        window.y = this.formTimbrado
        return (
            <Form
                layout="vertical"
                ref={this.formTimbrado}
                initialValues={{
                    TipoDeComprobante: 'I',
                    Moneda: 'MXN',
                    Fecha: moment().subtract(1, 'hour'),
                    Exportacion: '01',
                    enviar_receptor: true,
                    enviar_responsable: true,
                    Descuento: 0,
                    descuento: null,
                    DescuentoPorcentual: 0

                }}
                onFinish={this.formatValues}
                onValuesChange={this.updateValores}
                onFinishFailed={({ }) => {
                    Modal.error({
                        title: "Faltan campos para proceder con el timbrado",
                        content: "Revise los campos que hacen falta completar para timbrar correctamente"
                    })
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center" align="center">
                        <img src="/images/sat.svg" alt="SAT - Servicio de Administración Tributaria" width={"100%"} style={{ marginTop: "2em", marginBottom: "4em", maxWidth: 350 }} />
                    </Row>
                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} className="mb-1">Información del Documento Fiscal</Title>
                    </Row>
                    <Row gutter={[16, 16]} >
                        <Col xs={20} md={12}>

                            <Form.Item
                                name="Serie"
                                label="Serie"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la serie de la factura."
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    <Option value={process.env.REACT_APP_SPOT_SERIE}><strong>{process.env.REACT_APP_SPOT_SERIE}</strong> - SPOT</Option>
                                    <Option value={process.env.REACT_APP_VMI_FACTURA_SERIE}><strong>{process.env.REACT_APP_VMI_FACTURA_SERIE}</strong> - Factura de Almacen (VMI)</Option>
                                    <Option value={process.env.REACT_APP_COMPROBANTE_SERIE}><strong>{process.env.REACT_APP_COMPROBANTE_SERIE}</strong> - Comprobante de Pago</Option>
                                    <Option value={process.env.REACT_APP_NOTA_CREDITO}><strong>{process.env.REACT_APP_NOTA_CREDITO}</strong> - Nota de Crédito</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Folio"
                                label="Folio"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el folio"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Fecha"
                                label="Fecha"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la Fecha"
                                }]}
                            >
                                <DatePicker showTime className="width-100" disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="TipoDeComprobante"
                                label="Tipo De Comprobante"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Tipo de Comprobante"
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_TipoDeComprobante.map(({ tipo_comprobante, descripción }) => <Option filter={`${tipo_comprobante} ${descripción}`} value={tipo_comprobante}><strong>{tipo_comprobante}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="FormaPago"
                                label="Forma de Pago"
                            >
                                <Select disabled={this.props.disabled}>
                                    {[
                                        <Option value={null}>SIN FORMA DE PAGO</Option>,
                                        ...c_FormaPago.map(({ forma_pago, descripcion }) => <Option value={forma_pago}><strong>{forma_pago}</strong> - {descripcion}</Option>)
                                    ]}
                                </Select>
                            </Form.Item>
                        </Col>
                        {(!this.props.transaccion_id) && <Col xs={20} md={12}>
                            <Form.Item
                                name="MetodoPago"
                                label="Método de Pago"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Método de Pago"
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    <Option value={'PUE'}><strong>PUE</strong> - Pago en Una sola Exhibición</Option>
                                    <Option value={'PPD'}><strong>PPD</strong> - Pago en Parcialidades o Diferido</Option>
                                </Select>
                            </Form.Item>
                        </Col>}
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Exportacion"
                                label="Exportacion"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Tipo de Exportación"
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    {c_Exportacion.map(({ exportacion, descripción }) => <Option filter={`${exportacion} ${descripción}`} value={exportacion}><strong>{exportacion}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={12}>
                            <Form.Item
                                name="LugarExpedicion"
                                label="Lugar Expedicion (C.P.)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese un código postal"
                                }]}
                            >
                                <Input disabled={this.props.disabled} className="width-100" />
                            </Form.Item>

                        </Col>
                    </Row>
                    {this.props.transaccion_id && <>
                        <Row>
                            <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                            <Title level={5}>Información de Relación a Factura</Title>
                        </Row>
                        <Row className="mb-1" gutter={[16, 16]}>
                            <Col xs={20} md={8}>
                                <Form.Item
                                    name="transaccion_id"
                                    label="Transacción a generar el Comprobante"
                                    rules={[{
                                        required: true,
                                        message: "Seleccione una transacción para generar el comprobante"
                                    }]}
                                >
                                    <Select
                                        placeholder="Seleccione la Transacción o el Pago a Timbrar"
                                        filterOption={false}
                                        showArrow
                                        className="width-100"
                                        onSearch={(search) => this.getTransacciones({ search, page: 1 })}
                                        showSearch
                                        allowClear
                                        labelInValue
                                    >
                                        {this.state.transacciones.data.map(transaccion => <Option value={transaccion._id}>
                                            {this.renderTransaccion(transaccion)}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={20} md={8}>
                                <Form.Item
                                    name={["CfdiRelacionados ", "UUID"]}
                                    label="Factura a relacionar"
                                    rules={[{
                                        required: true,
                                        message: "Seleccione la factura a relacionar"
                                    }]}
                                >
                                    <Select
                                        placeholder="Seleccione la factura o el pago a timbrar"
                                        filterOption={false}
                                        showArrow
                                        className="width-100"
                                        onSearch={(search) => this.getFacturas({ search, page: 1 })}
                                        showSearch
                                        allowClear
                                        mode="multiple"
                                        onSelect={(value, { content }) => {
                                            let facturas = [...this.state.factura]
                                            facturas.push({ ...content })
                                            this.setState({ factura: facturas })
                                        }
                                        }
                                        onDeselect={(value_des) => {
                                            let facturas = [...this.state.factura]

                                            let index = facturas.findIndex(e => e.uuid === value_des)
                                            if (index != -1) {
                                                facturas.splice(index, 1)
                                            }

                                            this.setState({ factura: facturas })
                                        }}
                                    >
                                        {this.state.facturas.data.map(factura => <Option value={factura.uuid} content={factura}>
                                            {this.renderFactura(factura)}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={20} md={8}>
                                <Form.Item
                                    name={["CfdiRelacionados ", "TipoRelacion"]}
                                    label="Facturación Relacional"
                                    rules={[{
                                        required: true,
                                        message: "Ingrese la Razón Social desde donde se efectuará la factura"
                                    }]}
                                >
                                    <Select
                                        disabled={this.props.disabled}
                                        placeholder="Seleccione el tipo de Relación"
                                        filterOption={false}
                                    >
                                        {c_TipoDeRelación.map(({ tipo_relacion, descripción }) => <Option filter={`${tipo_relacion} ${descripción}`} value={tipo_relacion}><strong>{tipo_relacion}</strong> - <Text style={{ wordWrap: 'break-word' }}>{descripción}</Text></Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}

                    {this.props.nota_credito_drawer ? <>
                        <Row>
                            <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                            <Title level={5}>Información de Relación a Facturas</Title>
                        </Row>
                        <Row className="mb-1" gutter={[16, 16]}>
                            <Col xs={20} md={8}>
                                <Form.Item
                                    name={["CfdiRelacionados ", "UUID"]}
                                    label="Factura a relacionar"
                                    rules={[{
                                        required: true,
                                        message: "Seleccione la factura a relacionar"
                                    }]}
                                >
                                    <Select
                                        placeholder="Seleccione la factura o el pago a timbrar"
                                        filterOption={false}
                                        showArrow
                                        className="width-100"
                                        onSearch={(search) => this.getFacturas({ search, page: 1 })}
                                        showSearch
                                        allowClear
                                        mode="multiple"
                                        onSelect={(value, { content }) => {
                                            this.onSelectFacturasNotaCreadito({ value, content })
                                        }}
                                        onDeselect={(value_des) => {
                                            let facturas = [...this.state.facturas_nota_credito]
                                            let index = facturas.findIndex(e => e.uuid === value_des)
                                            if (index != -1) {
                                                facturas.splice(index, 1)
                                            }
                                            this.setState({ facturas_nota_credito: facturas })
                                        }}
                                    >
                                        {this.state.facturas.data.map(factura => <Option value={factura.uuid} content={factura}>
                                            {this.renderFactura(factura)}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={20} md={8}>
                                <Form.Item
                                    name={["CfdiRelacionados ", "TipoRelacion"]}
                                    label="Facturación Relacional"
                                    rules={[{
                                        required: true,
                                        message: "Ingrese la Razón Social desde donde se efectuará la factura"
                                    }]}
                                >
                                    <Select
                                        disabled={this.props.disabled}
                                        placeholder="Seleccione el tipo de Relación"
                                        filterOption={false}
                                    >
                                        {c_TipoDeRelación.map(({ tipo_relacion, descripción }) => <Option filter={`${tipo_relacion} ${descripción}`} value={tipo_relacion}><strong>{tipo_relacion}</strong> - <Text style={{ wordWrap: 'break-word' }}>{descripción}</Text></Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </> : null}

                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} className="mb-1">Información del Emisor</Title>
                    </Row>
                    <Row className="mb-1">
                        <Col span={24}>
                            <Form.Item noStyle
                                name="enviar_responsable"
                                valuePropName="checked">
                                <Checkbox >Enviar al Responsable una copia de la factura</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[16, 16]} >
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="razon_social_id"
                                label="Razón Social"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la Razón Social desde donde se efectuará la factura"
                                }]}
                            >
                                <Select
                                    disabled={this.props.disabled}
                                    placeholder="Seleccione la razon social"
                                    onSearch={(search) => this.getRazonesSociales({ page: 1, search })}
                                    filterOption={false}
                                    onSelect={(value, { razon_social }) => {
                                        this.formTimbrado.current.setFieldsValue({
                                            Emisor: {
                                                Nombre: razon_social.razon_social,
                                                Rfc: razon_social.rfc,
                                                RegimenFiscal: razon_social.regimen_fiscal,
                                            },
                                        })
                                        this.setState({ razon_social_id: razon_social._id })
                                    }}
                                >
                                    {this.state.razones_sociales.data.map(rs => <Option value={rs._id} razon_social={rs}  >{rs.razon_social}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "Nombre"]}
                                label="Nombre del Emisor"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre del Emisor (como se registró en el SAT)"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "Rfc"]}
                                label="Registro Federal de Contribuyentes (RFC)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el RFC del Emisor"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "RegimenFiscal"]}
                                // name="RegimenFiscal"
                                label="Régimen Fiscal"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Régimen Fiscal"
                                }]}
                            >
                                <Select
                                    disabled={this.props.disabled}
                                    showSearch
                                    optionFilterProp="filter"
                                >
                                    {c_RegimenFiscal.map(({ regimen_fiscal, descripción }) => <Option filter={`${regimen_fiscal} ${descripción}`} value={regimen_fiscal}><strong>{regimen_fiscal}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "DomicilioFiscalEmisor"]}
                                label="Domicilio Fiscal Emisor (Código Postal)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el domicilio del emisor."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} className="mb-1">Información del Receptor</Title>
                    </Row>
                    <Row className="mb-1">
                        <Col span={24}>
                            <Form.Item noStyle
                                name="enviar_receptor"
                                valuePropName="checked">
                                <Checkbox >Enviar al Receptor una copia de la factura</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={20} md={24}>
                            <Form.Item
                                name={["Receptor", "Nombre"]}
                                label="Nombre / Razón Social del Emisor"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Nombre / Razón Social del Receptor"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "Rfc"]}
                                label="Registro Federal de Contribuyentes (RFC)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el RFC del cliente / receptor."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "DomicilioFiscalReceptor"]}
                                label="Domicilio Fiscal Receptor (Código Postal)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el domicilio del cliente."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "UsoCFDI"]}
                                label="Uso del CFDI"
                                rules={[{
                                    required: true,
                                    message: "Seleccione una opción."
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_UsoCFDI.map(({ uso_cfdi, descripción }) => <Option filter={`${uso_cfdi} ${descripción}`} value={uso_cfdi}><strong>{uso_cfdi}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "RegimenFiscalReceptor"]}
                                label="Régimen Físcal Receptor"
                                rules={[{
                                    required: true,
                                    message: "Seleccione un régimen fiscal"
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_RegimenFiscal.map(({ regimen_fiscal, descripción }) => <Option filter={`${regimen_fiscal} ${descripción}`} value={regimen_fiscal}><strong>{regimen_fiscal}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5}>Información de Pagos y Productos</Title>
                    </Row>

                    <Row className="mb-3" gutter={[16, 16]}>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="Moneda"
                                label="Moneda"
                            >
                                <Select disabled={this.props.disabled} onSelect={moneda => this.setState({ moneda })}>
                                    <Option value={'MXN'}>MXN</Option>
                                    <Option value={'USD'}>USD</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="TipoCambio"
                                label="Tipo de Cambio"
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value && value.replace(/\$\s?|(,*)/g, '')}
                                    disabled={this.state.moneda == "MXN"}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={24} >
                            <Form.Item
                                label="Observaciones"
                                name="observaciones"
                                rules={[{
                                    max: 1000,
                                    message: "Máximo de caracteres alcanzado (1000)"
                                }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                        {(this.props.transaccion_id) && <Paragraph strong >Si está generando un recibo de pago en dolares, es necesario revisar que tanto la cantidad en dolares como la cantidad de pesos concuerden con la orden y la factura</Paragraph>}
                        
                        { this.props.transaccion_id && <Col xs={24} >
                            <Form.Item noStyle
                                name="incluir_ImpuestosDR"
                                valuePropName="checked">
                                <Checkbox defaultChecked={true}>Incluir desglose de impuestos (Documentos Relacionados) en la factura, marcar los documentos relacionados como objeto de impuestos</Checkbox>
                            </Form.Item>
                        </Col>}

                        <Col xs={24}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) => true}
                            // shouldUpdate={(prevValues, curValues) => prevValues['descuento'] !== curValues['descuento'] || prevValues['Concepto'] !== curValues['descuento']}
                            >
                                {({ getFieldValue, ...s }) => {
                                    return <Form.List
                                        prefixCls="Conceptos"
                                        name="Conceptos"
                                    >

                                        {(fields, { add, remove }, { errors }) => (
                                            <Table
                                                className="table-products"
                                                size="small"
                                                columns={(() => {

                                                    const x = {
                                                        "ClaveProdServ": "32131000",
                                                        "NoIdentificacion": "8UC6034",
                                                        "Cantidad": 1,
                                                        "ClaveUnidad": "H87",
                                                        "Unidad": "Pieza",
                                                        "Descripcion": "Circuit breaker",
                                                        "ValorUnitario": "665",
                                                        "Importe": "665",
                                                        "Descuento": "0",
                                                        "ObjetoImp": "02",
                                                        "Impuestos": {
                                                            "Traslados": [
                                                                {
                                                                    "Base": "665",
                                                                    "Importe": "106.4",
                                                                    "Impuesto": "002",
                                                                    "TasaOCuota": "0.160000",
                                                                    "TipoFactor": "Tasa"
                                                                }
                                                            ]
                                                        }
                                                    }

                                                    let pipeline = [
                                                        {

                                                            title: "ClaveProdServ",
                                                            dataIndex: "ClaveProdServ",
                                                            key: "ClaveProdServ",
                                                            width: this.props.transaccion_id ? 70 : 40,
                                                            render: (item, { name }, index) => <Form.Item name={[name, "ClaveProdServ"]} noStyle

                                                                rules={!this.props.transaccion_id ? [
                                                                    {
                                                                        required: true,
                                                                        message: "Ingresé el codigo de producto o servicio adecuado"
                                                                    }
                                                                ] : []}
                                                            >
                                                                <Input disabled={!this.props.transaccion_id} bordered={false} />
                                                            </Form.Item>,
                                                        },
                                                    ]

                                                    if (!this.props.transaccion_id)
                                                        pipeline.push({
                                                            width: 40,
                                                            fixed: "left",
                                                            title: "No Identificacion (SKU)",
                                                            dataIndex: "NoIdentificacion",
                                                            key: "NoIdentificacion",
                                                            width: 60,
                                                            render: (item, { key, name, }) => <Form.Item name={[name, "NoIdentificacion"]} noStyle>
                                                                <Input disabled bordered={false} />
                                                            </Form.Item>
                                                        })

                                                    pipeline.push(
                                                        {
                                                            title: "Descripcion",
                                                            dataIndex: "Descripcion",
                                                            key: "Descripcion",
                                                            width: 100,
                                                            render: (item, { name }, index) => <span className="text-center">
                                                                {(!this.props.transaccion_id) && <>
                                                                    <Form.Item name={[name, "_id"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                    <Form.Item name={[name, "NoIdentificacion"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                    <Form.Item name={[name, "Descripcion"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                    <Form.Item name={[name, "Descuento"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                    <Form.Item name={[name, "ObjetoImp"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                    <Form.Item name={[name, "Impuestos", "Traslados", 0, "Base"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                    <Form.Item name={[name, "Impuestos", "Traslados", 0, "Impuesto"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                    <Form.Item name={[name, "Impuestos", "Traslados", 0, "TasaOCuota"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                    <Form.Item name={[name, "Impuestos", "Traslados", 0, "TipoFactor"]} noStyle>
                                                                        <input type="hidden" />
                                                                    </Form.Item>
                                                                </>}
                                                                <Form.Item name={[name, "Descripcion"]} noStyle>
                                                                    <Input.TextArea autoSize disabled bordered={false} />
                                                                </Form.Item>
                                                            </span>,
                                                        },
                                                        {
                                                            title: "Cantidad",
                                                            dataIndex: "Cantidad",
                                                            key: "Cantidad",
                                                            width: 50,
                                                            render: (text, { name }, index) => <Form.Item name={[name, "Cantidad"]}>
                                                                <InputNumber
                                                                    controls={false}
                                                                    disabled={this.props.disabled || !!this.props.transaccion_id}
                                                                    // onChange={e => this.updateValores({ ...record, cantidad: e }, index)}
                                                                    bordered={false}
                                                                    className="text-center" />
                                                            </Form.Item>
                                                            ,
                                                        },
                                                        {
                                                            title: "Unidad",
                                                            dataIndex: "Unidad",
                                                            key: "Unidad",
                                                            width: 50,
                                                            render: (text, { name }, index) => <Form.Item
                                                                name={[name, 'ClaveUnidad']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        whitespace: true,
                                                                        message: "Falta el Material",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select disabled={this.props.disabled} bordered={false}>
                                                                    <Option value={'H87'}>Pieza</Option>
                                                                    <Option value={'EA'}>Elemento</Option>
                                                                    <Option value={'KGM'}>Kilogramo</Option>
                                                                    <Option value={'MTR'}>Metro</Option>
                                                                    <Option value={'KT'}>Kit</Option>
                                                                    <Option value={'SET'}>Conjunto</Option>
                                                                    <Option value={'LTR'}>Litro</Option>
                                                                    <Option value={'XBX'}>Caja</Option>
                                                                    <Option value={'xun'}>Unidad</Option>
                                                                    <Option value={'GRM'}>Gramo</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        },
                                                    )

                                                    pipeline.push(
                                                        {
                                                            title: "Valor Unitario",
                                                            dataIndex: "ValorUnitario",
                                                            key: "ValorUnitario",
                                                            width: 40,
                                                            render: (text, { name }, index) => <Form.Item name={[name, "ValorUnitario"]} noStyle>
                                                                <InputNumber
                                                                    style={{ textAlign: "end", width: "100%" }}
                                                                    controls={false}
                                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    disabled={this.props.disabled}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                    bordered={false}
                                                                />
                                                            </Form.Item>,
                                                        },
                                                    )

                                                    pipeline.push({
                                                        title: "Importe",
                                                        dataIndex: "Importe",
                                                        key: "Importe",
                                                        width: 50,
                                                        fixed: 'right',
                                                        render: (text, { name }, index) => <Form.Item name={[name, "Importe"]} noStyle>
                                                            <InputNumber
                                                                style={{ textAlign: "end", width: "100%" }}
                                                                controls={false}
                                                                disabled={this.props.disabled}
                                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                bordered={false}
                                                            />
                                                        </Form.Item>
                                                    })

                                                    if (this.props.nota_credito_drawer
                                                        && (getFieldValue("descuento"))
                                                    ) {
                                                        pipeline.push({
                                                            title: "Descuento",
                                                            dataIndex: "Descuento",
                                                            key: "Descuento",
                                                            width: 40,

                                                            render: (text, { name }, index) =>
                                                                <Form.Item
                                                                    name={[name, "Descuento"]}
                                                                    // name={[name, "Impuestos", "Traslados", 0, "Descuento"]}
                                                                    noStyle>
                                                                    <Input
                                                                        style={{ textAlign: "end", width: "100%" }}
                                                                        controls={false}
                                                                        disabled={this.props.disabled}
                                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                        bordered={false}

                                                                    />
                                                                </Form.Item>,
                                                        },)
                                                    }


                                                    if (!this.props.transaccion_id)
                                                        pipeline.push({
                                                            title: "Impuestos",
                                                            dataIndex: "Impuestos ",
                                                            key: "Impuestos",

                                                            width: 50,
                                                            fixed: 'right',

                                                            render: (text, { name }, index) => <Space>
                                                                <Form.Item
                                                                    name={[name, "NoImpuesto"]}
                                                                    noStyle
                                                                    valuePropName="checked"
                                                                >
                                                                    <Checkbox />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[name, "Impuestos", "Traslados", 0, "Importe"]}
                                                                    noStyle
                                                                >
                                                                    <InputNumber

                                                                        style={{ textAlign: "end", width: "100%" }}
                                                                        controls={false}
                                                                        disabled={this.props.disabled}
                                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                        bordered={false}
                                                                    />
                                                                </Form.Item>
                                                            </Space>
                                                        })

                                                    if (!this.props.transaccion_id)
                                                        pipeline.push({
                                                            title: "Total",
                                                            dataIndex: "Total",
                                                            key: "Total",

                                                            width: 50,
                                                            fixed: 'right',

                                                            render: (text, { name }, index) => <>
                                                                <Form.Item
                                                                    name={[name, "Total"]}
                                                                    noStyle
                                                                >
                                                                    <InputNumber
                                                                        style={{ textAlign: "end", width: "100%" }}
                                                                        controls={false}
                                                                        disabled={this.props.disabled}
                                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                        bordered={false}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[name, "Importe"]}
                                                                    noStyle
                                                                >
                                                                    <InputNumber
                                                                        style={{ textAlign: "end", width: "100%", display: 'none' }}
                                                                        controls={false}
                                                                        disabled={this.props.disabled}
                                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                        bordered={false}
                                                                    />
                                                                </Form.Item>
                                                            </>
                                                        })

                                                    return pipeline
                                                })()}
                                                dataSource={fields}
                                                pagination={false}
                                                scroll={{ x: 1000, }}
                                                bordered={true}
                                            />
                                        )}
                                    </Form.List>
                                }}

                            </Form.Item>

                            <List
                                className="list-totals"
                                size="small"
                                bordered={false}
                            >

                                {this.props.nota_credito_drawer && <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues['descuento'] !== curValues['descuento']}>
                                    {({ getFieldValue, ...s }) => {

                                        if (getFieldValue("descuento"))
                                            return (
                                                <List.Item>
                                                    <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                                        <Text strong >
                                                            Subtotal sin Descuento &nbsp;
                                                        </Text>
                                                        <Form.Item name="SubTotalDescuento" noStyle>
                                                            <InputNumber
                                                                size="large"
                                                                controls={false}
                                                                style={{ minWidth: 150 }}
                                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                disabled={this.props.disabled}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                bordered={false} />
                                                        </Form.Item>
                                                    </Space>
                                                </List.Item>
                                            );
                                    }}
                                </Form.Item>}

                                {
                                    this.props.nota_credito_drawer && <List.Item>
                                        <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                            <Space>
                                                <Text strong >
                                                    Descuento &nbsp;
                                                </Text>
                                                <Form.Item
                                                    noStyle
                                                    name={"descuento"}
                                                    buttonStyle="solid"
                                                >
                                                    <Radio.Group buttonStyle="solid" size="small">
                                                        <Radio.Button value={null}>Sin Descuento</Radio.Button>
                                                        <Radio.Button value={"porcentual"}>Porcentual</Radio.Button>
                                                        {/*                                                         <Radio.Button value={"cantidad"}>Cantidad</Radio.Button> */}
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => curValues['descuento'] != prevValues['descuento']}>
                                                    {({ getFieldValue, getFieldsValue, }) => {
                                                        if (getFieldValue("descuento") == "porcentual")
                                                            return (
                                                                <Form.Item name="DescuentoPorcentual" noStyle>
                                                                    <InputNumber
                                                                        size="small"
                                                                        controls={false}
                                                                        // style={{ minWidth: 150 }}
                                                                        min={0}
                                                                        max={100}
                                                                        formatter={(value) => `${value}%`}
                                                                        parser={(value) => Number(value?.replace('%', '') ?? 0)}

                                                                        bordered={true} />
                                                                </Form.Item>
                                                            );
                                                    }}
                                                </Form.Item>
                                            </Space>

                                        </Space>
                                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => curValues['descuento'] != prevValues['descuento']}>
                                            {({ getFieldValue }) => {
                                                return <Form.Item name="Descuento" noStyle>
                                                    <InputNumber
                                                        size="large"
                                                        controls={false}
                                                        style={{ minWidth: 150 }}
                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        disabled={this.props.disabled ||
                                                            (getFieldValue('descuento') == null) ||
                                                            (getFieldValue('descuento') == 'porcentual')
                                                        }
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                        bordered={false} />
                                                </Form.Item>
                                            }}
                                        </Form.Item>
                                    </List.Item>
                                }

                                <List.Item>
                                    <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                        <Text strong >SubTotal</Text>
                                        <Form.Item name="SubTotal" noStyle>
                                            <InputNumber
                                                size="large"
                                                controls={false}
                                                style={{ minWidth: 150 }}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                disabled={this.props.disabled}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                bordered={false} />
                                        </Form.Item>

                                    </Space>
                                </List.Item>

                                {(!this.props.transaccion_id) && <>
                                    <List.Item>
                                        <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                            <Text strong >Total Impuestos Trasladados (IVA) (16%)</Text>
                                            <Form.Item name={["ImpuestosBase"]} noStyle>
                                                <InputNumber
                                                    style={{ display: 'none' }}
                                                    size="large"
                                                    controls={false}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    disabled={this.props.disabled}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    bordered={false} />
                                            </Form.Item>
                                            <Form.Item name={["Impuestos", "TotalImpuestosTrasladados"]} noStyle>
                                                <InputNumber
                                                    size="large"
                                                    controls={false}
                                                    style={{ minWidth: 150 }}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    disabled={this.props.disabled}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    bordered={false} />
                                            </Form.Item>
                                        </Space>
                                    </List.Item>
                                </>}
                                <List.Item>
                                    <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                        <Text strong >Total</Text>
                                        <Form.Item name="Total" noStyle>
                                            <InputNumber
                                                size="large"
                                                controls={false}
                                                style={{ minWidth: 150 }}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                disabled={this.props.disabled}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                bordered={false} />
                                        </Form.Item>
                                    </Space>
                                </List.Item>

                                {(this.props.transaccion_id) && <>
                                    <List.Item style={{ opacity: this.state.moneda == "USD" ? undefined : 0.5 }}>
                                        <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                            <Text strong >Total (USD)</Text>
                                            <Form.Item name={["TotalDolar"]} noStyle>
                                                <InputNumber
                                                    disabled={this.state.moneda != "USD"}
                                                    size="large"
                                                    controls={false}
                                                    style={{ minWidth: 150 }}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    bordered={false} />
                                            </Form.Item>
                                        </Space>
                                    </List.Item>
                                </>}
                            </List>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}
